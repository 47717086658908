<template>
	<div>
		<page-title v-if="!hideTitle"></page-title>
		<b-row class="pt-1">
			<b-col cols="12">
				<b-card no-body>
					<b-card-header>
						<div class="d-flex align-items-center">
							<feather-icon class="mr-1" icon="FilterIcon" />
							{{ $t("Filters") }}
						</div>
					</b-card-header>
					<b-card-body>
						<b-row>
							<b-col cols="3">
								<p style="margin-bottom: 0.5rem">{{ $t("Choose a date") }}</p>
								<flat-pickr
									v-model="filterValues.sync_time"
									:config="{
										wrap: true,
										time_24hr: true,
										mode: 'range',
										locale: $i18n.locale === 'cn' ? 'zh' : 'en',
									}"
									class="form-control"
								/>
							</b-col>
							<b-col cols="3">
								<p style="margin-bottom: 0.5rem">
									{{ $t("Alarm Type") }}
								</p>
								<v-select v-model="filterValues.type" :options="filterOptions.type" :placeholder="$t('No filter')" class="w-100" />
							</b-col>
							<b-col cols="3">
								<p style="margin-bottom: 0.5rem">
									{{ $t("Alarm Position") }}
								</p>
								<v-select v-model="filterValues.position" :options="filterOptions.position" :placeholder="$t('No filter')" class="w-100" />
							</b-col>
							<b-col cols="3">
								<p style="margin-bottom: 0.5rem">
									{{ $t("Alarm Degree") }}
								</p>
								<v-select v-model="filterValues.grade" :options="filterOptions.grade" :placeholder="$t('No filter')" class="w-100" />
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>

			<!-- Table Container Card -->
			<b-col cols="12">
				<b-card no-body>
					<div class="m-2">
						<!-- Table Top -->
						<b-row>
							<!-- Per Page -->
							<b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="12" md="6">
								<label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
								<v-select v-model="perPage" :clearable="false" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" class="per-page-selector d-inline-block mx-50" size="sm" />
								<label style="margin: 0.5rem 0">{{ $t("Entries") }}</label>
							</b-col>
						</b-row>
					</div>

					<b-table
						ref="refAlarmListTable"
						:busy="isBusy"
						:empty-text="$t('No Data')"
						:fields="tableColumns"
						:items="fetchAlarms"
						:sort-by.sync="sortBy"
						:sort-desc.sync="isSortDirDesc"
						bordered
						class="text-center position-relative"
						hover
						primary-key="alarm_id"
						responsive
						show-empty
						small
						striped
					>
						<template v-slot:table-busy>
							<div class="text-center my-2">
								<b-spinner class="align-middle mr-1"></b-spinner>
								<strong>{{ $t("Loading") }}...</strong>
							</div>
						</template>
						<!-- Column: Index -->
						<template #cell(index)="data">
							<div class="text-nowrap">
								<span class="align-text-top text-capitalize">
									{{ data.index + 1 }}
								</span>
							</div>
						</template>
						<!-- Column: Index -->
						<template #cell(actions)="data">
							<b-button v-b-modal:detail-alarms-modal class="btn-icon" size="sm" variant="warning" @click="detailValueId = data.item.valueid">
								<feather-icon icon="MoreHorizontalIcon" />
							</b-button>
						</template>
					</b-table>
					<div class="mx-2 mb-2">
						<b-row>
							<b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
								<span class="text-muted">
									{{
										`
                                    ${$t("Show")}
                                    ${dataMeta.from}
                                    ${$t("to")}
                                    ${dataMeta.to}
                                    ${$t("of")}
                                    ${total}
                                    ${$t("Entries")}
                                    `
									}}
								</span>
							</b-col>
							<!-- Pagination -->
							<b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
								<b-pagination v-model="currentPage" :per-page="perPage" :total-rows="total" hide-goto-end-buttons size="sm"></b-pagination>
							</b-col>
						</b-row>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-modal id="detail-alarms-modal" hide-footer size="xl" title="警报详情" @hidden="detailValueId = null">
			<b-card no-body>
				<div class="my-1">
					<!-- Table Top -->
					<b-row>
						<!-- Per Page -->
						<b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="12" md="6">
							<label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
							<v-select v-model="detailPerPage" :clearable="false" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="detailPerPageOptions" class="per-page-selector d-inline-block mx-50" size="sm" />
							<label style="margin: 0.5rem 0">{{ $t("Entries") }}</label>
						</b-col>
					</b-row>
				</div>

				<b-table
					ref="refDetailAlarmListTable"
					:empty-text="$t('No Data')"
					:fields="detailTableColumns"
					:items="fetchAlarms"
					:sort-by.sync="detailSortBy"
					:sort-desc.sync="detailIsSortDirDesc"
					bordered
					class="text-center position-relative"
					hover
					primary-key="alarm_id"
					responsive
					show-empty
					small
					striped
				>
					<!-- Column: Index -->
					<template #cell(index)="data">
						<div class="text-nowrap">
							<span class="align-text-top text-capitalize">
								{{ data.index + 1 }}
							</span>
						</div>
					</template>
					<!-- Column: Index -->
					<template #cell(cur_waring_val)="data">
						<div style="min-width: 10rem">
							<span class="text-bold">{{ data.item.cur_waring_val }} </span>
							<small v-if="data.item.cur_waring_val > data.item.max" class="text-danger">超出上限</small>
							<small v-else-if="data.item.cur_waring_val < data.item.min" class="text-danger">低于下限</small>
							<small v-else class="text-success">正常</small>
						</div>
					</template>
					<template #cell(sync_time)="data">
						<span>{{ momentFmt(data.item.sync_time) }}</span>
					</template>
				</b-table>
				<div class="mx-2 mb-2">
					<b-row>
						<b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
							<span class="text-muted">
								{{
									`
                                ${$t("Show")}
                                ${detailDataMeta.from}
                                ${$t("to")}
                                ${detailDataMeta.to}
                                ${$t("of")}
                                ${detailTotal}
                                ${$t("Entries")}
                                `
								}}
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
							<b-pagination v-model="detailCurrentPage" :per-page="detailPerPage" :total-rows="detailTotal" hide-goto-end-buttons size="sm"></b-pagination>
						</b-col>
					</b-row>
				</div>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
	import vSelect from "vue-select";
	import JsonExcel from "vue-json-excel";
	import flatPickr from "vue-flatpickr-component";
	import zh from "flatpickr/dist/l10n/zh.js";
	import Ripple from "vue-ripple-directive";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { showToast } from "@/libs/utils/showToast";
	import { getAlarms, getAlarmsOptions } from "@/api/front";
	import PageTitle from "@/views/layout/PageTitle";
	import { routerParams } from "@/libs/utils/routerParams";
	import { t } from "@core/libs/i18n/utils";
	import { momentFmt, momentJs } from "@/libs/utils/moment";

	export default {
		directives: {
			Ripple,
		},
		components: {
			PageTitle,
			vSelect,
			flatPickr,
			downloadExcel: JsonExcel,
		},
		props: ["hide-title"],
		setup() {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				filterOptions: {},
				filterValues: {
					grade: null,
					type: null,
					position: null,
					sys_type: null,
					sync_time: momentJs().format(),
				},
				isEditAlarmSidebarActive: false,
				refAlarmListTable: null,
				perPage: 10,
				searchQuery: "",
				tableColumns: [
					{
						label: "#",
						key: "index",
					},
					{
						label: "报警名称",
						key: "srname",
					},
					{
						label: "报警值id",
						key: "valueid",
						sortable: true,
					},
					{
						label: "位置",
						key: "position",
						sortable: true,
					},
					{
						label: "报警等级",
						key: "grade",
					},
					{
						label: "报警类型",
						key: "type",
					},
					{
						label: "系统分类",
						key: "sys_type",
					},
					{
						label: "下限值",
						key: "min",
					},
					{
						label: "上限值",
						key: "max",
					},
					{
						label: "报警次数",
						key: "count",
					},
					{
						label: "操作",
						key: "actions",
					},
				],
				total: 0,
				currentPage: 1,
				perPageOptions: [10, 25, 50, 100],
				sortBy: "",
				isSortDirDesc: false,
				dataMeta: computed(() => {
					const localItemsCount = event.refAlarmListTable ? event.refAlarmListTable.localItems.length : 0;
					return {
						from: event.perPage * (event.currentPage - 1) + (localItemsCount ? 1 : 0),
						to: event.perPage * (event.currentPage - 1) + localItemsCount,
						of: event.total,
					};
				}),
				detailValueId: null,
				detailTableColumns: [
					{
						label: "#",
						key: "index",
					},
					{
						label: "报警名称",
						key: "srname",
					},
					{
						label: "报警值id",
						key: "valueid",
						sortable: true,
					},
					{
						label: "位置",
						key: "position",
						sortable: true,
					},
					{
						label: "报警等级",
						key: "grade",
					},
					{
						label: "报警类型",
						key: "type",
					},
					{
						label: "系统分类",
						key: "sys_type",
					},
					{
						label: "下限值",
						key: "min",
					},
					{
						label: "上限值",
						key: "max",
					},
					{
						label: "时间",
						key: "sync_time",
					},
					{
						label: "报警值",
						key: "cur_waring_val",
					},
				],
				refDetailAlarmListTable: null,
				detailPerPage: 10,
				detailTotal: 0,
				detailCurrentPage: 1,
				detailPerPageOptions: [10, 25, 50, 100],
				detailSortBy: "",
				detailIsSortDirDesc: false,
				detailDataMeta: computed(() => {
					const localItemsCount = event.refDetailAlarmListTable ? event.refDetailAlarmListTable.localItems.length : 0;
					return {
						from: event.detailPerPage * (event.detailCurrentPage - 1) + (localItemsCount ? 1 : 0),
						to: event.detailPerPage * (event.detailCurrentPage - 1) + localItemsCount,
						of: event.detailTotal,
					};
				}),
				isBusy: false,
			});

			watch([() => event.currentPage, () => event.perPage], () => {
				refetchData();
			});

			watch(
				event.filterValues,
				() => {
					refetchData();
				},
				"deep"
			);

			watch([() => event.detailCurrentPage, () => event.detailPerPage], () => {
				refetchDetailData();
			});

			onMounted(() => {
				getOptions();
			});

			const refetchData = () => {
				event.refAlarmListTable.refresh();
			};
			const refetchDetailData = () => {
				event.refDetailAlarmListTable.refresh();
			};

			const fetchAlarms = (ctx, callback) => {
				event.isBusy = true;
				const filters = getFilters();
				let params = {
					project_id: event.project_id,
					...filters,
				};
				if (event.detailValueId) {
					params = {
						...params,
						valueid: event.detailValueId,
						sortBy: event.detailSortBy,
						sortDesc: event.detailIsSortDirDesc ? 1 : 0,
						pagesize: event.detailPerPage,
						page: event.detailCurrentPage,
					};
				} else {
					params = {
						...params,
						sortBy: event.sortBy,
						sortDesc: event.isSortDirDesc ? 1 : 0,
						pagesize: event.perPage,
						page: event.currentPage,
					};
				}
				getList(params)
					.then((response) => {
						const { list, total } = response.data;
						callback(list);
						if (event.detailValueId) {
							event.detailTotal = total;
						} else {
							event.total = total;
						}
						event.isBusy = false;
					})
					.catch((error) => {
						event.isBusy = false;
						showToast(error);
					});
			};

			/**
			 * 获取列表数据
			 */
			const getList = async (params) => {
				return getAlarms({
					project_id: event.project_id,
					...params,
				});
			};
			/**
			 * 获取参数
			 */
			const getOptions = () => {
				getAlarmsOptions({
					project_id: event.project_id,
				})
					.then((response) => {
						event.filterOptions = response.data;
					})
					.catch((error) => {
						showToast(error);
					});
			};
			/**
			 * 处理导出数据的头部
			 * @param fields
			 */
			const exportExcelHeaders = (fields) => {
				let headers = {};
				fields.map((item) => {
					if (item.key !== "index" && item.key !== "actions") {
						let header = t(item.label) + "(" + item.key + ")";
						headers[header] = item.key;
					}
				});
				return headers;
			};
			/**
			 * 获取筛选条件
			 */
			const getFilters = () => {
				let filters = {};
				for (const Key in event.filterValues) {
					let Value = event.filterValues[Key];
					if (!!Value) {
						filters[Key] = Value;
						if (Key === "sync_time") {
							Value = Value.replaceAll(" 至 ", ",").replaceAll(" to ", ",");
							const timeArr = Value.split(",");
							const start = momentJs(timeArr[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
							const end = momentJs(timeArr[1] || timeArr[0])
								.endOf("day")
								.format("YYYY-MM-DD HH:mm:ss");
							filters[Key] = "(" + start + "," + end + ")";
						}
					}
				}
				return filters;
			};
			/**
			 * 下载数据
			 * @returns {Promise<*>}
			 */
			const downloadData = async () => {
				const filters = getFilters();
				const params = {
					sortBy: event.sortBy,
					sortDesc: event.isSortDirDesc ? 1 : 0,
					...filters,
				};
				const res = await getList(params);
				return res.data.list;
			};

			return {
				...toRefs(event),
				fetchAlarms,
				exportExcelHeaders,
				downloadData,
				zh,
				momentFmt,
				momentJs,
			};
		},
	};
</script>

<style lang="scss">
	/* .per-page-selector {
  width: 90px;
}

.b-form-btn-label-control.dropdown.b-form-datepicker.form-control
  label.form-control {
  padding: 0.3rem 1rem;
} */
</style>

<style lang="scss">
	//noinspection CssUnknownTarget
	@import "../../../../@core/scss/vue/libs/vue-flatpicker";
</style>
